
import { defineComponent, ref, onUnmounted, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Discount } from '@/shared/interfaces/discount.model'
import { formatDate } from '@/shared/utils/formatDate'

export default defineComponent({
  components: {},

  setup () {
    const store = useStore()
    const isMobile = computed(() => window.innerWidth < 768)
    const trial = computed(() => store.state.discounts ? store.state.discounts.find((discount: Discount) => discount.trial_weeks && discount.trial_weeks > 0) : null)
    const otherDiscounts = computed(() => store.state.discounts ? store.state.discounts.filter((discount: Discount) => !discount.trial_weeks) : [])
    const currentSlide = ref(0)
    let slideInterval: any | undefined

    const images = [
      { src: require('@/assets/screenshots/book.jpg'), alt: 'Image Slide Book Generator' }
      // { src: require('@/assets/screenshots/temperatures.jpg'), alt: 'Image Slide Book Generator' }
    ]

    const nextSlide = () => {
      if (currentSlide.value < images.length - 1) {
        currentSlide.value++
      } else {
        currentSlide.value = 0
      }
    }

    const prevSlide = () => {
      if (currentSlide.value > 0) {
        currentSlide.value--
      } else {
        currentSlide.value = images.length - 1
      }
      stopAutoSlide()
      startAutoSlide()
    }

    const startAutoSlide = () => {
      slideInterval = setInterval(() => {
        nextSlide()
      }, 5000)
    }

    const stopAutoSlide = () => {
      if (slideInterval) {
        clearInterval(slideInterval)
        slideInterval = undefined
      }
    }
    const formatDateFun = (date: Date) => {
      return formatDate(date)
    }

    onMounted(() => {
      startAutoSlide()
    })

    onUnmounted(() => {
      stopAutoSlide()
    })

    return {
      currentSlide,
      images,
      nextSlide,
      prevSlide,
      formatDateFun,
      isMobile,
      trial,
      otherDiscounts
    }
  }
})
