import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/banermobile.png'
import _imports_1 from '@/assets/landing.png'


const _hoisted_1 = { class: "flex flex-col justify-between" }
const _hoisted_2 = { class: "flex-1 overflow-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "text-md text-left w-full border-2 border-red-700 rounded p-2 mt-5"
}
const _hoisted_4 = { class: "font-bold text-lg text-center" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "flex flex-col lg:flex-row items-center lg:items-stretch space-x-0 lg:space-x-2 mb-2" }
const _hoisted_8 = { class: "flex-1 mb-4 lg:mb-0 lg:pr-6 bg-white p-6 rounded shadow-lg flex items-center justify-center" }
const _hoisted_9 = { class: "text-center lg:text-center" }
const _hoisted_10 = { class: "text-2xl font-semibold text-gray-900" }
const _hoisted_11 = { class: "text-left text-lg mt-4 text-gray-600" }
const _hoisted_12 = { class: "flex justify-center items-center gap-2" }
const _hoisted_13 = {
  href: "/#/auth/signup",
  class: "primary mt-4 inline-block px-8 py-3 text-lg text-white bg-primary-600 rounded-lg hover:bg-primary-700"
}
const _hoisted_14 = {
  href: "/#/about",
  class: "primary mt-4 inline-block px-8 py-3 text-lg text-white bg-primary-600 rounded-lg hover:bg-primary-700"
}
const _hoisted_15 = { class: "w-full lg:w-1/2 relative p-4 rounded shadow-lg" }
const _hoisted_16 = ["src", "alt"]
const _hoisted_17 = {
  key: 0,
  class: "w-full h-half object-cover mt-4",
  src: _imports_0,
  alt: "Mobile Landing Image"
}
const _hoisted_18 = {
  key: 1,
  class: "w-full h-half object-cover",
  src: _imports_1,
  alt: "Landing Image"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      (_ctx.otherDiscounts && _ctx.otherDiscounts.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('discounts.title')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherDiscounts, (discount, index) => {
              return (_openBlock(), _createElementBlock("div", { key: index }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t(discount.description + '_home', { percent: discount.percentValue, static: discount.staticValue }),
                  class: "me-1"
                }, null, 8, _hoisted_5),
                (discount.endDateToJoin)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      innerHTML: _ctx.$t('discounts.valid', { validTo: _ctx.formatDateFun(new Date(discount.endDateToJoin)) })
                    }, null, 8, _hoisted_6))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('homepage.header')), 1),
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('homepage.text')), 1),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.trial && _ctx.trial.trial_weeks ? _ctx.$t('nav.buttonStart', { weeks: _ctx.trial.trial_weeks}) : _ctx.$t('nav.buttonStartNoTrial')), 1),
              _createElementVNode("a", _hoisted_14, _toDisplayString(_ctx.$t('homepage.learnMore')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
            return _withDirectives((_openBlock(), _createElementBlock("img", {
              key: index,
              class: "w-full h-full object-contain",
              src: image.src,
              alt: image.alt
            }, null, 8, _hoisted_16)), [
              [_vShow, _ctx.currentSlide === index]
            ])
          }), 128))
        ])
      ])
    ]),
    (_ctx.isMobile)
      ? (_openBlock(), _createElementBlock("img", _hoisted_17))
      : (_openBlock(), _createElementBlock("img", _hoisted_18))
  ]))
}